import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BuildForm } from 'app/models/formulir/build-form.model';
import { Adapter } from '../adapter';

@Injectable({
  providedIn: 'root',
})
export class BuildFormAdapter implements Adapter<BuildForm> {
  public adapt(item: any): BuildForm {
    return {
      id: item.idBuildForm,
      namaForm: item.namaForm,
      namaTable: item.namaTable,
      dateMake: moment(item.dateMake).toDate(),
      type: item.type,
      idGroupBuildForm: item.idGroupBuildForm,
      instalasi: { id: item.idInstallasi, nama: item.namaInstallasi },
      navigation: { id: item.idNavigation, icon: item.iconNavigasi, nama: item.namaNavigasi },
      target: Number(item.target),
    };
  }
}
