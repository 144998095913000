import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataKlinikAdapter } from '../adapters/data-klinik.adapter.service';
import { Observable, of, throwError } from 'rxjs';
import { DataKlinik, DataKlinikV2 } from '../../models/data-klinik.model';
import { map, catchError } from 'rxjs/operators';
import { ClinicEnvironmentService } from '../clinic-environment.service';
import { HmacSHA256, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class DataKlinikApiService {
  private _signature() {
    const signature = HmacSHA256(environment.apiId + ':' + environment.apiPass, environment.apiSecret);
    const encodedSignature = signature.toString(enc.Base64);
    return encodedSignature;
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Key': this._signature(),
    }),
  };

  constructor(
    private http: HttpClient,
    private adapter: DataKlinikAdapter,
    private clinicEnv: ClinicEnvironmentService
  ) {}

  public getDataKlinik(idDataKlinik?: number): Observable<DataKlinik> {
    const url = idDataKlinik
      ? `${environment.url}/data-klinik/${idDataKlinik}`
      : `${environment.url}/data-klinik/${this.clinicEnv.clinicId || environment.klinik}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        if (!data) {
          return new DataKlinik(null);
        }
        return this.adapter.adapt(data);
      }),
      catchError(() => of(new DataKlinik(null)))
    );
  }

  public getDataKlinikV2(idDataKlinik?: any): Observable<DataKlinikV2> {
    const url = idDataKlinik
      ? `${environment.urlV2}/data-klinik/${idDataKlinik}/mobile`
      : `${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId || environment.klinik}/mobile`;
    return this.http.get(url).pipe(
      map((data: any) => {
        if (!data) {
          return new DataKlinikV2(null);
        }

        if (idDataKlinik === 'all') {
          return data.responses.dataKlinik.map((val: any) => this.adapter.adaptV2(val));
        } else {
          return data.responses.dataKlinik.map((val: any) => this.adapter.adaptV2(val))[0];
        }
      }),
      catchError((err) => throwError(err))
    );
  }

  public getAllDataKlinikV2(idDataKlinik?: number): Observable<DataKlinikV2> {
    const url = idDataKlinik
      ? `${environment.urlV2}/data-klinik/${idDataKlinik}`
      : `${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId || environment.klinik}`;
    return this.http.get(url, this.httpOptions).pipe(
      map((data: any) => {
        if (!data) {
          return new DataKlinikV2(null, null);
        }
        return data.responses.dataKlinik.map((val: any) => this.adapter.adaptV2(val))[0];
      }),
      catchError(() => of(new DataKlinikV2(null, null)))
    );
  }

  public getDataHeadOffice(idGroupDataKlinik: number): Observable<DataKlinikV2> {
    return this.http.get(`${environment.urlV2}/data-klinik/head-office/${idGroupDataKlinik}`).pipe(
      map((data: any) => {
        if (!data) {
          return new DataKlinikV2(null);
        }

        return data.responses.dataKlinik.map((val: any) => this.adapter.adaptV2(val))[0];
      }),
      catchError((err) => throwError(err))
    );
  }

  public searchByCustomerCode(code: string): Observable<DataKlinikV2> {
    return this.http.get(`${environment.urlV2}/data-klinik/kode-klinik/${code}`).pipe(
      map((data: any) => {
        if (!data) return null;
        return this.adapter.adaptV2(data.responses.dataKlinik);
      }),
      catchError((err) => throwError(err))
    );
  }

  public searchByURL(url: string): Observable<DataKlinikV2> {
    return this.http.get(`${environment.urlV2}/data-klinik/url/${url}/mobile`).pipe(
      map((data: any) => {
        if (!data) return null;
        return this.adapter.adaptV2(data.responses.dataKlinik[0]);
      }),
      catchError((err) => throwError(err))
    );
  }
}
