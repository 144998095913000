import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ClinicEnvironmentService } from './clinic-environment.service';
import { StorageService } from './storage/storage.service';
import { storageDict } from './storage/storage-dict';

@Injectable({
  providedIn: 'root',
})
export class FcmTopicsService {
  constructor(
    private clinicEnv: ClinicEnvironmentService,
    // private firebaseX: FirebaseX,
    private platform: Platform,
    private storage: StorageService
  ) {}

  private async _unsubscribeTopic(topicName: string): Promise<any> {
    if (this.platform.is('capacitor')) {
      // Native apps iOS/Android
      return;
      // return this.firebaseX.unsubscribe(`c-${this.clinicEnv.clinicId}.${topicName}`);
    } else {
      throw 'platform-not-supported';
    }
  }

  private async _subscribeTopic(topicName: string): Promise<any> {
    if (this.platform.is('capacitor')) {
      // Native apps iOS/Android
      return;
      // return this.firebaseX.subscribe(`c-${this.clinicEnv.clinicId}.${topicName}`);
    } else {
      throw 'platform-not-supported';
    }
  }

  public async initTopicSubscription(topicName: string): Promise<any> {
    let topicSubscribed: {
      [key: string]: boolean;
    } = await this.storage.getItem(storageDict.fcmTopicsSubscribed).toPromise();

    if (!topicSubscribed || topicSubscribed[topicName] === undefined) {
      topicSubscribed = {};
      await this._subscribeTopic(topicName);
      topicSubscribed[topicName] = true;
      await this.storage.setItem(storageDict.fcmTopicsSubscribed, topicSubscribed).toPromise();
      return;
    }
  }

  /** Unsubscribe from a topic, where user can not listen or receive any notifications in the topic. */
  public async unsubscribeToTopic(topicName: string) {
    try {
      console.log('unsubscribing...');

      let topicSubscribed: {
        [key: string]: boolean;
      } = await this.storage.getItem(storageDict.fcmTopicsSubscribed).toPromise();
      if (!topicSubscribed) {
        return;
      }

      if (topicSubscribed[topicName] === false) {
        return;
      }

      await this._unsubscribeTopic(topicName);
      topicSubscribed[topicName] = false;
      await this.storage.setItem(storageDict.fcmTopicsSubscribed, topicSubscribed).toPromise();
    } catch (err) {
      throw err;
    }
  }

  /** Subscribe the user/token to the topic, so they can listen notifications in the topic. */
  public async subscribeToTopic(topicName: string) {
    try {
      console.log('subscribing...');
      let topicSubscribed: {
        [key: string]: boolean;
      } = await this.storage.getItem(storageDict.fcmTopicsSubscribed).toPromise();
      if (!topicSubscribed) {
        this.initTopicSubscription(topicName);
        return;
      }

      if (topicSubscribed[topicName] === true) {
        return;
      }

      await this._subscribeTopic(topicName);
      topicSubscribed[topicName] = true;
      await this.storage.setItem(storageDict.fcmTopicsSubscribed, topicSubscribed).toPromise();
    } catch (err) {
      throw err;
    }
  }
}
