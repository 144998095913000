import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimezoneInterceptor implements HttpInterceptor {
  private timezoneDb;
  private timezoneOffset;

  constructor() {
    this.timezoneDb = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneOffset = new Date().getTimezoneOffset() / 60;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(req).pipe(
      map(() => {
        return req.clone({
          setHeaders: {
            'client-timezone-iana': this.timezoneDb,
            'client-timezone-offset': `${this.timezoneOffset}`,
          },
        });
      }),
      switchMap((req) => next.handle(req))
    );
  }
}
