import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailNavigasi } from '../../models/detail-navigasi.model';
import { DetailNavigasiApiService } from '../api/detail-navigasi.api.service';

@Injectable({
  providedIn: 'root',
})
export class DetailNavigasiDataService {
  constructor(private apiService: DetailNavigasiApiService) {}

  public getByLogin(loginId: number): Observable<DetailNavigasi[]> {
    return this.apiService.getByLogin(loginId);
  }

  public createRecords(loginId: number, navItems: number[]) {
    return this.apiService.createRecords(loginId, navItems);
  }

  public updateRecords(loginId: number, navItems: number[]) {
    return this.apiService.updateRecords(loginId, navItems);
  }

  public createNavigasiRegistrasiKlinik(loginId: number, data: any) {
    return this.apiService.createNavigasiRegisterKlinik(loginId, data);
  }
}
