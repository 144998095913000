export default class ValueGuard {
  public static againtsNullOrUndefined(argument: any): boolean {
    if (argument === null || argument === undefined) {
      return false;
    }
    return true;
  }

  public static againtsNullOrUndefinedBulk(args: any[]): boolean {
    for (const arg of args) {
      const isSafe = this.againtsNullOrUndefined(arg);
      if (!isSafe) {
        return false;
      }
    }
    return true;
  }

  public static againtsInvalidLength(argument: string, max: number, min?: number): boolean {
    const length = argument.trim().length;
    if (min) {
      const isInRage = length >= min && length <= max;
      if (!isInRage) {
        return false;
      }
      return true;
    } else {
      if (length > max) {
        return false;
      }
      return true;
    }
  }

  public static againtsNegativeValue(argument: number): boolean {
    return argument >= 0;
  }
}
