<ion-app style="max-width: 1200px; margin: 0px auto">
  <ion-split-pane when="(min-width: 768px)" contentId="content">
    <ion-menu auto-hide="false" id="menu-material" contentId="content" menuId="loginMenu">
      <ion-header mode="md" class="ion-no-border">
        <ion-grid>
          <ion-row class="img-row">
            <ng-container *ngIf="stateImage === true; else urlString">
              <img [src]="logo" />
            </ng-container>
            <ng-template #urlString>
              <img src="{{ clinicLogo }}" />
            </ng-template>
          </ion-row>
          <ion-row>
            <div class="user-info-wrapper" *ngIf="!isKaryawanLoading; else karyawanLoading">
              <p class="category-text">{{ karyawan?.kategoriKaryawan?.nama }}</p>
              <p class="identity-text">{{ karyawan?.nama }} </p>
              <p class="clinic-text">{{ clinic?.nama }}</p>
            </div>
            <ng-template #karyawanLoading>
              <div class="user-info-wrapper">
                <ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 30%;"></ion-skeleton-text>
              </div>
            </ng-template>
          </ion-row>
        </ion-grid>
        <ion-searchbar mode="md" placeholder="Cari" class="menu-searchbar" debounce="750" [(ngModel)]="searchText"
          (ionChange)="searchMenu()"></ion-searchbar>
        <div class="dropdown-list-wrapper" *ngIf="filteredMenuItems">
          <div class="dropdown-list"
            *ngIf="filteredMenuItems?.length !== 0 && (searchText || searchText !== ''); else emptyList">
            <div *ngFor="let menu of filteredMenuItems" class="dropdown-list-menu"
              [ngClass]="choosenMenu?.title === menu.title ? 'active' : ''" [routerLink]="parseURL(menu.url).target"
              routerLinkActive="active" (click)="closeMenu()">
              <ion-icon [src]="menu?.icon"></ion-icon>
              <p>{{ menu?.title }}</p>
            </div>
          </div>
          <ng-template #emptyList>
            <div class="dropdown-list">
              <p class="not-found-text">Menu tidak ditemukan, atau Anda tidak memiliki akses.</p>
            </div>
          </ng-template>
        </div>
      </ion-header>

      <ion-content>
        <div class="sidebar-root">
          <ng-container *ngIf="isSidebarLoading; else sidebarReady">
            <div *ngFor="let i of [].constructor(8)" class="skeleton">
              <ion-skeleton-text animated class="icon"></ion-skeleton-text>
              <ion-skeleton-text animated class="text"></ion-skeleton-text>
            </div>
          </ng-container>
          <ng-template #sidebarReady>
            <div class="sidebar-group">
              <div class="sidebar-item-wrapper">
                <div class="sidebar-item beranda" routerLink="/dashboard" routerDirection="root"
                  routerLinkActive="active" (click)="closeMenu()">
                  <div class="item-inner">
                    <ion-icon src="assets/icon/navigation/beranda-icon.svg" slot="start"></ion-icon>
                    <span>Beranda</span>
                  </div>
                </div>
              </div>
              <div class="sidebar-item-wrapper">
                <div class="sidebar-item profil" routerLink="/logout" routerDirection="root" routerLinkActive="active"
                  (click)="closeMenu()">
                  <div class="item-inner">
                    <ion-icon src="assets/icon/navigation/beranda-icon.svg" slot="start"></ion-icon>
                    <span>Profil</span>
                  </div>
                </div>
              </div>
              <div class="sidebar-item-wrapper" *ngFor="let parent of sidebarMenu; index as i">
                <div class="sidebar-item-outline-wrapper" (click)="toggleSubmenu(i)" [class.selected]="parent.isOpen">
                  <div class="marker"></div>
                  <div class="sidebar-item has-child">
                    <div class="item-inner">
                      <ion-icon [src]="parent.sidebarMenu.icon" slot="start"></ion-icon>
                      <span>{{ parent.sidebarMenu.title }}</span>
                    </div>
                    <ion-icon name="chevron-down" class="chevron" [@chevronDown]="parent.isOpen"></ion-icon>
                  </div>
                </div>
                <div class="sidebar-item-child-wrapper">
                  <div class="vertical-line"></div>
                  <div class="item-children" [@submenuOpen]="parent.isOpen">
                    <div class="sidebar-item child" *ngFor="let child of parent.sidebarMenu.children; index as j"
                      [routerLink]="parseURL(child.url).target" [queryParams]="parseURL(child.url).params"
                      routerLinkActive="active" (click)="closeMenu()">
                      <div class="item-inner">
                        <span class="item-child-title">{{ child.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="content"></ion-router-outlet>
  </ion-split-pane>
  <app-video-call-popover *ngIf="videoCallPopover"></app-video-call-popover>
</ion-app>