import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ForegroundNotificationService {
  private toast: HTMLIonToastElement;

  constructor(private toastController: ToastController, private router: Router) {}

  async showNotification(title, body, redirect, duration?: number) {
    this.toast = await this.toastController.create({
      header: title,
      message: body,
      animated: true,
      id: 'notif-toast',
      position: 'top',
      duration: duration || 2000,
      buttons: [
        {
          side: 'end',
          icon: 'close',
          handler: () => {
            this.toast.onclick = () => {};
          },
        },
      ],
    });
    await this.toast.present();
    this.toast.onclick = (ev) => {
      this.router.navigateByUrl(redirect);
    };
  }
}
