import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import io, { Manager, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  private currentRoom = '';

  constructor() {}

  public onEvent(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (val: any) => {
        subscriber.next(val);
      });
    });
  }

  public joinRoom(roomId: string) {
    this.socket.emit('joinRoom', roomId);
    this.currentRoom = roomId;
  }

  public leaveRoom(roomId: string) {
    this.socket.emit('leaveRoom', roomId);
    this.currentRoom = '';
  }

  public initSocket() {
    const socketURL = new URL(`${environment.urlChatV2}`);
    // console.log(socketURL.origin);

    this.socket = io(socketURL.origin, {
      upgrade: true,
      path: '/socket.io',
      transports: ['websocket'],
      autoConnect: true,
    });
    this.socket.on('connect', () => {
      if (this.currentRoom) {
        this.joinRoom(this.currentRoom);
      }
    });
    this.socket.on('connect_error', (err) => {
      // console.warn(err);
    });
  }
}
