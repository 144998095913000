import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import * as tv from 'twilio-video';
import {
  connect,
  createLocalTracks,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteParticipant,
  Room,
} from 'twilio-video';
import { VideoCallApiService } from './api/video-call.api.service';
import { VideoCallDataService } from './data/video-call.data.service';

@Injectable({
  providedIn: 'root',
})
export class VideoCallService {
  public popOverState$ = new BehaviorSubject<boolean>(false);

  public participantConnected$ = new Subject<RemoteParticipant>();
  public participantDisconnected$ = new Subject<RemoteParticipant>();
  public roomDisconnected$ = new Subject<Room>();

  public micMute$ = new BehaviorSubject<boolean>(false);
  public cameraFacing$ = new BehaviorSubject<'user' | 'environment'>('user');

  public activeRoom: Room;
  public remoteParticipant: RemoteParticipant;
  public userTracks: LocalTrack[] = [];

  // These variabled used for reconnection, back to the video room.
  /** active room's resume URL, includes room name, identity, and appointmentID */
  public resumeURL: string;

  constructor(private videoCallDataService: VideoCallDataService) {
    this.popOverState$.next(false);
  }

  private _participantConnected(participant: RemoteParticipant) {
    this.remoteParticipant = participant;
    this.participantConnected$.next(participant);
  }

  /** Connect to a twilio room. Creates a new one if it is not available. */
  public async connectRoom(roomName: string | number) {
    if (this.activeRoom) {
      throw 'Room already connected';
    }
    if (this.userTracks.length === 0) {
      await this.initializeLocalTracks();
    }

    const accessToken = await this.videoCallDataService.getAccessToken(`${roomName}`).toPromise();
    try {
      const room = await connect(accessToken.token, {
        name: `${roomName}`,
        tracks: this.userTracks,
      });

      this.activeRoom = room;

      /** Handle Room Events */
      room.participants.forEach(this._participantConnected.bind(this));
      room.on('participantConnected', this._participantConnected.bind(this));
      room.on('participantDisconnected', (participant) => {
        this.remoteParticipant = undefined;
        this.participantDisconnected$.next(participant);
      });
      room.once('disconnected', (room: Room) => {
        this.roomDisconnected$.next(room);

        room.localParticipant.tracks.forEach((pub) => {
          if (pub.kind === 'video') {
            const localVideoTrack = pub.track as LocalVideoTrack;
            localVideoTrack.detach().forEach((element) => element.remove());
            localVideoTrack.stop();
          } else if (pub.kind === 'audio') {
            const localAudioTrack = pub.track as LocalAudioTrack;
            localAudioTrack.detach().forEach((element) => element.remove());
            localAudioTrack.stop();
          }
          console.log('unpublishing tracks...');
          pub.unpublish();
        });

        this.activeRoom = undefined;
        this.remoteParticipant = undefined;
        this.userTracks = [];
      });

      return room;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public async initializeLocalTracks() {
    this.userTracks = await createLocalTracks({
      audio: true,
      video: { height: 480, facingMode: 'user' },
    });
    const audioTrack = this.userTracks.filter((item) => item.kind === 'audio')[0] as LocalAudioTrack;
    const videoTrack = this.userTracks.filter((item) => item.kind === 'video')[0] as LocalVideoTrack;

    return {
      audioTrack,
      videoTrack,
    };
  }

  public destroyTracks() {
    if (!this.userTracks) return;
    this.userTracks.forEach((track: LocalAudioTrack | LocalVideoTrack) => {
      if (track.kind === 'audio' || track.kind === 'video') {
        track.detach();
        track.stop();
      }
    });
    this.userTracks = [];
  }

  public async getLocalTracks() {
    if (this.userTracks.length === 0) {
      return this.initializeLocalTracks();
    }

    const audioTrack = this.userTracks.filter((item) => item.kind === 'audio')[0] as LocalAudioTrack;
    const videoTrack = this.userTracks.filter((item) => item.kind === 'video')[0] as LocalVideoTrack;

    return {
      audioTrack,
      videoTrack,
    };
  }

  public async disconnectRoom() {
    if (this.activeRoom) {
      // await this.videoCallApi.completeRoom(this.activeRoom.name);
      this.activeRoom.disconnect();
    } else {
      // Error happens, reset them.
      this.dismissPopOver();
      this.activeRoom = undefined;
      this.remoteParticipant = undefined;
      this.userTracks = [];
    }
  }

  public async toggleCamera() {
    const currentValue = this.cameraFacing$.getValue();
    const videoTrack = this.userTracks.find((track) => track.kind === 'video') as LocalVideoTrack;
    await videoTrack.restart({
      facingMode: currentValue === 'user' ? 'environment' : 'user',
    });
  }

  public toggleMicrophone() {
    const currentValue = this.micMute$.getValue();
    this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
      if (currentValue === true) {
        publication.track.enable();
        this.micMute$.next(false);
      } else {
        publication.track.disable();
        this.micMute$.next(true);
      }
    });
  }

  public getPopOverState() {
    return this.popOverState$.getValue();
  }

  public presentPopOver() {
    this.popOverState$.next(true);
  }

  public dismissPopOver() {
    this.popOverState$.next(false);
  }
}
