import { SidebarMenu } from 'app/models/application-menu.model';
/**
 * Menu items on the aside menu, will be displayed on `aside.component.ts`.
 * This static menu is used for superadmin only (static, all access).
 * Support up to 3 levels of children.
 */

export const superadminItems: SidebarMenu[] = [
  {
    title: 'Dashboard',
    icon: 'assets/icon/navigation/dashboard-icon.svg',
    children: [
      {
        title: 'Dashboard Kedatangan Pasien',
        url: '/dashboard-kedatangan-pasien',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Dashboard Keuangan',
        url: '/dashboard-keuangan',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Dashboard Kepegawaian',
        url: '/dashboard-kepegawaian',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Dashboard Rawat Inap',
        url: '/dashboard-rawat-inap',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Dashboard Farmasi',
        url: '/dashboard-farmasi',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Medical Record',
    icon: 'assets/icon/navigation/medical-record-icon.svg',
    children: [
      {
        title: 'Pendaftaran Pasien Baru',
        url: '/pendaftaran-pasien-baru',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Mini Pendaftaran Pasien Baru',
        url: '/mini-pendaftaran-pasien-baru',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Pendaftaran Pasien Baru Online',
        url: '/pendaftaran-pasien-baru-online',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Pendaftaran Rawat Jalan Online',
        url: '/pendaftaran-rawat-jalan-online',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Pasien',
        url: '/pasien',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Non Pasien',
        url: '/non-pasien',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Tracer Pasien',
        url: '/tracer-pasien',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Surat Pasien Pulang',
        url: '/manage-surat-pasien-pulang',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Manage Hapus Tracer Pasien',
        url: '/manage-delete-tracer-pasien',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Petugas Rekam Medis Pasien',
        url: '/petugas-rm',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Jadwal Dokter',
        // url: '/embedded-window?target=/medical-record/jadwal-dokter',
        url: '/manage-jadwal-dokter',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Jadwal Kunjungan Berikutnya',
        icon: '',
        url: '/medical-record/jadwal-kunjungan-berikutnya',
        // url: '/embedded-window?target=medical-record/janji-kunjungan',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Koding ICD',
        url: '/koding-icd',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Pindah Rekam Medis',
        url: '/manage-pindah-nomor-rekam-medis',
        icon: '',
        clinicType: ['rs', 'rsp', 'klinik', 'partner'],
      },
      {
        title: 'Manage Batal Kunjungan',
        url: '/manage-batal-kunjungan',
        icon: '',
        clinicType: ['rs', 'rsp', 'klinik', 'partner'],
      },
    ],
  },
  {
    title: 'Medis',
    icon: 'assets/icon/navigation/medis-icon.svg',
    children: [
      {
        title: 'Antrian Konsulen',
        url: '/antrian-konsulen',
        icon: '',
        clinicId: [1, 7],
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Antrian Pasien Poli Klinik',
        url: '/antrien-pasien-poliklinik',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Antrian Pasien Rawat Inap',
        url: '/antrian-pasien-ranap',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Surat Medis',
    icon: `assets/icon/navigation/laporan-medis-icon.svg`,
    children: [
      {
        title: 'Manage Rujuk Klinik Eksternal',
        url: '/manage-rujuk-klinik-external',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Surat Sehat',
        url: '/surat-sehat',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Surat Sakit',
        url: '/surat-sakit',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Surat Bebas Narkoba',
        url: '/embedded-window?target=medis/surat-bebas-narkoba',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Surat Keterangan Kesehatan Jiwa',
        url: '/embedded-window?target=medis/surat-keterangan-kesehatan-jiwa',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Surat Keterangan Pemeriksaan Mata',
        url: '/embedded-window?target=medis/surat-keterangan-pemeriksaan-mata',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Telemedicine',
    icon: 'assets/icon/navigation/medis-icon.svg',
    children: [
      {
        title: 'Diagnosa Awal',
        url: '/diagnosa-awal',
        icon: '',
        clinicId: [1, 13, 85, 144],
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Diagnosa UKGS',
        url: '/diagnosa-ukgs',
        icon: '',
        clinicId: [1, 13, 85, 144],
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Chat Now',
        url: '/chat-finder',
        icon: '',
        clinicId: [1, 13, 85, 144],
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Farmasi',
    icon: 'assets/icon/navigation/farmasi-icon.svg',
    children: [
      {
        title: 'Tulis Resep Digital',
        url: '/tulis-resep-digital',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Antrian Resep Digital',
        url: '/antrian-resep-digital',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Riwayat Resep Digital',
        url: '/riwayat-resep-digital',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Tulis Resep Digital Rawat Inap',
        url: '/tulis-resep-digital-rawat-inap',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Antrian Resep Digital Rawat Inap',
        url: '/antrian-resep-digital-rawat-inap',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Riwayat Resep Digital Rawat Inap',
        url: '/riwayat-resep-digital-rawat-inap',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Kasir',
    icon: 'assets/icon/navigation/kasir-icon.svg',
    children: [
      {
        title: 'Point of Sales',
        url: '/pos-v2',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Antrian Pasien Rawat Jalan',
        url: '/antrian-pasien-rawat-jalan',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Cetak Kasir',
        url: '/manage-cetak-kasir',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Cetak Kasir Online',
        url: '/manage-cetak-kasir-online',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Batal Kwitansi',
        url: '/manage-batal-kwitansi',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      // {
      //   title: 'Manage Batal Kasir Pasien Online',
      //   url: '/manage-batal-kasir-pasien-mobile',
      //   icon: '',
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
    ],
  },
  {
    title: 'Logistik',
    icon: 'assets/icon/navigation/logistik-icon.svg',
    children: [
      {
        title: 'Manage Item',
        url: '/manage-item',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      // {
      //   title: 'Manage Item',
      //   url: '/manage-item-v2',
      //   icon: '',
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
      {
        title: 'Manage Permintaan Distribusi',
        url: '/permintaan-gudang',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Penerimaan Distribusi',
        url: '/penerimaan-gudang',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Stock Opname',
        url: '/pengeluaran-gudang',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Adjustment Stock',
        url: '/adjustment-stok',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Goods Receiving',
        url: '/goods-receiving',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Purchase Retur',
        url: '/manage-purchase-retur',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Jenis Pemakaian Barang',
        url: '/manage-pengeluaran-gudang',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Retur Permintaan Gudang',
        url: '/retur-permintaan-gudang',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Item Nonaktif',
        url: '/manage-item-nonaktif',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Keuangan',
    icon: 'assets/icon/navigation/keuangan-icon.svg',
    children: [
      {
        title: 'Manage Chart Of Account',
        url: '/manage-coa',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Tindakan',
        url: '/manage-tindakan',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Tarif Tindakan',
        url: '/manage-tarif-tindakan',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Asset Management',
        url: '/manage-asset-management',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Paket Multiple Tindakan',
        url: '/paket-multiple-tindakan',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Tindakan Online',
        url: '/manage-tindakan-online',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Sharing Fee',
        url: '/manage-sharing-fee',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      // {
      //   title: 'Manage Sharing Fee Produk',
      //   url: '/embedded-window?target=keuangan/manage-sharing-fee-product',
      //   icon: ``,
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
      {
        title: 'Manage Pembayaran A/P',
        url: '/manage-pembayaran-ap',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Pembayaran O/P Jasa Tenaga Medis',
        url: '/manage-pembayaran-op',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Pemasukan',
        url: '/manage-pemasukan-ar',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Sharing Fee Produk',
        url: '/manage-sharing-fee-produk',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Manage Jasa Pelayanan Berdasarkan Transaksi',
        url: '/embedded-window?target=keuangan/manage-jasa-pelayanan-berdasarkan-transaksi',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Expense',
        url: '/manage-expense',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Neraca',
        url: '/embedded-window?target=keuangan/laporan-neraca',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Laba Rugi',
        url: '/embedded-window?target=keuangan/laporan-laba-rugi',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Jurnal Umum',
        url: '/embedded-window?target=keuangan/jurnal-umum',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Tindakan Non Aktif',
        url: '/manage-tindakan-nonaktif',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Dashboard E-Wallet',
        url: '/dashboard-ewallet',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Laporan',
    icon: `assets/icon/navigation/laporan-medis-icon.svg`,
    children: [
      {
        title: 'Laporan Alos',
        url: '/embedded-window?target=laporan/laporan-alos',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'rscustomrsch'],
      },
      {
        title: 'Laporan Telemedicine',
        url: '/embedded-window?target=laporan/laporan-telemedicine',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Laporan Jasa Tenaga Medis',
        url: '/embedded-window?target=laporan/laporan-jasa-tenaga-medis',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Laporan Bahan Alat Medis',
        url: '/embedded-window?target=laporan/laporan-bahan-alat-medis',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'rscustomrsch'],
      },
    ],
  },
  {
    title: 'Laporan Keuangan',
    icon: 'assets/icon/navigation/keuangan-icon.svg',
    children: [
      {
        title: 'Laporan Pasien Belum Bayar',
        url: '/laporan-keuangan-pasien-belum-bayar',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Laporan Kasir',
    icon: `assets/icon/navigation/laporan-medis-icon.svg`,
    children: [
      // {
      //   title: 'Laporan Kasir Pasien',
      //   url: '/embedded-window?target=laporan/laporan-kasir-pasien',
      //   icon: ``,
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
      // {
      //   title: 'Detail Laporan Kasir Per Mahasiswa',
      //   url: '/embedded-window?target=laporan/detail-laporan-kasir-per-mahasiswa',
      //   icon: ``,
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
      // {
      //   title: 'Laporan Pembayaran Piutang',
      //   url: '/embedded-window?target=laporan/laporan-pembayaran-piutang',
      //   icon: ``,
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
      {
        title: 'Detail Laporan Kasir',
        url: '/embedded-window?target=laporan/detail-laporan-kasir',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Ringkasan Laporan Kasir/POS',
        url: '/embedded-window?target=laporan/ringkasan-laporan-kasir',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Detail Laporan Kasir Pasien & Dokter',
        url: '/embedded-window?target=laporan/detail-laporan-kasir-pos-by-dokter',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Laporan Diskon',
        url: '/embedded-window?target=laporan/laporan-diskon',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Laporan Pegawai',
    icon: 'assets/icon/navigation/pegawai-icon.svg',
    children: [
      {
        title: 'Kalender Cuti Pegawai',
        url: '/kalender-cuti-pegawai',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
    ],
  },
  {
    title: 'Marketing',
    icon: 'assets/icon/navigation/marketing-icon.svg',
    children: [
      {
        title: 'Manage Diskon',
        url: '/manage-kupon',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Diskon Pembayaran',
        url: '/manage-kupon-pembayaran',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Pegawai',
    icon: 'assets/icon/navigation/pegawai-icon.svg',
    children: [
      {
        title: 'Screening Covid',
        url: '/screening-covid-ladokgi',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp'],
        clinicId: [1, 20],
      },
      {
        title: 'Data Pegawai',
        url: '/manage-pegawai',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Manage Approval Cuti Pegawai',
        url: '/approval-surat-cuti',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Manage Approval Unit Kerja ',
        url: '/approval-unit-kerja',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Manage Jam Kerja',
        url: '/manage-jam-kerja',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Manage Penilaian Kinerja Pegawai',
        url: '/manage-penilaian-kinerja-pegawai',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      // {
      //   title: 'Manage Pegawai Non Aktif',
      //   url: '/manage-pegawai-nonaktif',
      //   icon: '',
      //   clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      // },
    ],
  },
  {
    title: 'E-Office',
    icon: 'assets/icon/navigation/e-office-icon.svg',
    children: [
      {
        title: 'Surat Masuk',
        url: '/surat-masuk',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Surat Keluar',
        url: '/surat-keluar',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Kategori E-Office',
        url: '/kategori-eoffice',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Manage Jenis File',
        url: '/jenis-file',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Manage File',
        url: '/file',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Sarana & Prasarana',
    icon: 'assets/icon/navigation/sarana-prasarana.svg',
    children: [
      {
        title: 'Absensi',
        icon: 'time-outline',
        url: '/absensi-karyawan',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Help Desk Maintenance',
        url: '/help-desk',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Tindak Lanjut Help Desk Maintenance',
        url: '/tindak-lanjut-help-desk',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Laporan Help Desk',
        url: '/laporan-help-desk',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
      {
        title: 'Manage Kategori Maintenance',
        url: '/manage-kategori-maintenance',
        icon: '',
        clinicType: ['rs', 'rsp'],
      },
    ],
  },
  {
    title: 'PCare',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Diagnosa',
        url: '/pcare-diagnosa',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Dokter',
        url: '/pcare-dokter',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Kelompok',
        url: '/pcare-kelompok',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Kesadaran',
        url: '/pcare-kesadaran',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Kunjungan',
        url: '/pcare-kunjungan',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'MCU',
        url: '/pcare-mcu',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Obat',
        url: '/pcare-obat',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Pendaftaran',
        url: '/pcare-pendaftaran',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Peserta',
        url: '/pcare-peserta',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Poli',
        url: '/pcare-poli',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Provider',
        url: '/pcare-provider',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Spesialis',
        url: '/pcare-specialis',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Status Pulang',
        url: '/pcare-status-pulang',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Tindakan',
        url: '/pcare-tindakan',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Manage Kunjungan',
        url: '/pcare-manage-kunjungan',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Manage Pendaftaran',
        url: '/pcare-manage-pendaftaran',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Manage Tindakan',
        url: '/pcare-manage-tindakan',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Manage Obat',
        url: '/pcare-manage-obat',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
      {
        title: 'Manage MCU',
        url: '/pcare-manage-mcu',
        clinicType: ['klinik', 'rs', 'rsp'],
        icon: '',
      },
    ],
  },
  {
    title: 'Izin Pegawai',
    icon: 'assets/icon/navigation/pegawai-icon.svg',
    children: [
      {
        title: 'Permohonan Izin',
        icon: 'time-outline',
        url: '/manage-permohonan',
        clinicType: ['klinik', 'rs', 'rsp', 'rscustomrsch'],
      },
      {
        title: 'Approval Izin',
        url: '/manage-permohonan-approval',
        icon: '',
        clinicType: ['klinik', 'rs', 'rsp', 'rscustomrsch'],
      },
    ],
  },
  {
    title: 'CMS',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Customer Service',
        icon: 'time-outline',
        url: '/customer-service',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Dashboard Whatsapp',
        icon: 'time-outline',
        url: '/dashboard-whatsapp',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Master',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Master Instalasi',
        icon: '',
        url: '/master-installasi',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Poli Klinik',
        icon: '',
        url: '/master-poli-klinik',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Gudang',
        icon: '',
        url: '/master-gudang',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Rak Gudang',
        icon: '',
        url: '/master-rak',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Supplier',
        icon: '',
        url: '/master-supplier',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Cuti',
        icon: '',
        url: '/master-cuti',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Cuti Disable',
        icon: '',
        url: '/master-cuti-disable',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Unit Kerja',
        icon: '',
        url: '/master-unit-kerja',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Jabatan',
        icon: '',
        url: '/master-jabatan',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Metode Pembayaran',
        icon: '',
        url: '/master-metode-pembayaran',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Event',
        icon: '',
        url: '/master-event',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Banner',
        url: '/master-banner',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Kemasan',
        url: '/master-miscafac',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Signa',
        url: '/master-signa',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Biaya Tambahan Kasir',
        url: '/master-biaya-tambahan-kasir',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Tarif Odontogram',
        url: '/master-tarif-odontogram',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Pengaturan Software',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Manage User & Login',
        icon: 'key',
        url: '/manage-login',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
      {
        title: 'Manage Cuti Klinik',
        icon: 'key',
        url: '/setting-cuti',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Manage Permohonan Cuti',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Manage Permohonan Cuti',
        icon: '',
        url: '/permohonan-cuti',
        clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
      },
    ],
  },
  {
    title: 'SATUSEHAT',
    icon: `assets/icon/navigation/integrasi-icon.svg`,
    children: [
      {
        title: `Log SATUSEHAT Kemenkes`,
        icon: '',
        url: '/manage-log-satusehat',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
];
