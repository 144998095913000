import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Platform, IonRouterOutlet, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Observable, Subject, timer } from 'rxjs';
import { DataKlinikV2 } from './models/data-klinik.model';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';
import { FcmService } from './services/fcm.service';
import { VideoCallService } from './services/video-call.service';
import { delay, tap, takeUntil, finalize } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SidebarService, SidebarMenu } from './services/sidebar.service';
import { DataKlinikService } from './services/data/data-klinik.data.service';
import { ClinicEnvironmentService } from './services/clinic-environment.service';
import { StorageService } from './services/storage/storage.service';
import { storageDict } from './services/storage/storage-dict';
import { SwUpdate } from '@angular/service-worker';
import { SocketService } from './services/socket.service';
import { ElectronIPCService } from './services/electron-ipc.service';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { ToastService } from './services/toast.service';
import { VisitorsService } from './services/ip.service';
import { MessagingSocketService } from './services/messaging-socket.service';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { DownloadPhotoDataService } from './services/data/download-photo.data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageDownloaderService } from 'app/services/image-downloader.service';
import { Karyawan } from './models/karyawan.model';

interface SidebarItemGroup {
  sidebarMenu: SidebarMenu;
  isOpen: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger('submenuOpen', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', [animate(100)]),
    ]),
    trigger('chevronDown', [
      state('true', style({ transform: 'rotate(180deg)' })),
      transition('false <=> true', animate(100)),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  private unsubscribe$ = new Subject<void>();

  private lastTimeBackPress = 0;
  private timePeriodToExit = 2000;

  public clinic: any;
  public clinicURL = 'https://klinik.periksa.id/';
  public clinicLogo: string;
  public karyawan: Karyawan;
  public isKaryawanLoading: boolean = true;

  public searchText: string;
  public menuList = [];
  public filteredMenuItems: any;
  public lengthListMenu = 0;
  public choosenMenu: any;
  public currentIndex: any;

  public videoCallPopover = false;

  public logoUrl: string;
  public sidebarMenu: SidebarItemGroup[] = [];

  public isSidebarLoading = true;

  public logo: any;
  public logoName: any;
  public stateImage: boolean;
  private photo$: Observable<any>;

  constructor(
    public authService: AuthService,
    public router: Router,
    public clinicEnvService: ClinicEnvironmentService,
    public ipService: VisitorsService,
    private electronIPCService: ElectronIPCService,
    private videoCallService: VideoCallService,
    private dataKlinikService: DataKlinikService,
    private menuController: MenuController,
    private platform: Platform,
    private fcm: FcmService,
    private socketService: SocketService,
    private messagingSocketService: MessagingSocketService,
    private storageService: StorageService,
    private sidebarService: SidebarService,
    private swUpdate: SwUpdate,
    private sanitizer: DomSanitizer,
    private downloadPhotoDataService: DownloadPhotoDataService,
    private imageDownloaderService: ImageDownloaderService
  ) {
    this.initializeApp();
  }

  private initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        setTimeout(() => {
          SplashScreen.hide();
          StatusBar.setStyle({ style: StatusBarStyle.Dark });
          StatusBar.setBackgroundColor({ color: '#9083b7' });
        }, 1000);
      }
      this.getDataKlinik();
      if (this.swUpdate.isEnabled) {
        console.log('[SW] UpdateSW is enabled.');
        this.swUpdate.available.subscribe((ev) => {
          console.log(`Application update available (${ev.available})`);
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        });
      }
      this.initFCMModule();
      this.electronIPCService.initialize();
      this.messagingSocketService.initSocket();
      this.socketService.initSocket();
    });
  }

  private async initFCMModule() {
    await this.fcm.fetchAndStoreToken();
    this.fcm.attachListener();
  }

  private async getPhoto(logoName: string): Promise<void> {
    const url = `${environment.urlIconBucket}/${logoName}`;
    this.photo$ = this.imageDownloaderService.fetchImage(url).pipe(takeUntil(this.unsubscribe$));
    this.photo$
      .pipe(
        finalize(() => {
          this.storageService.getItem(storageDict.karyawan).subscribe((res) => {
            this.karyawan = res;
            this.isKaryawanLoading = false;
          });
        })
      )
      .subscribe(
        async (res) => {
          this.stateImage = true;
          this.logo = res;
        },
        (_) => {
          this.stateImage = false;
        }
      );
  }

  flattenMenuParents(listMenu: any) {
    const result = [];

    const pushResult = (item: SidebarMenu, icon: string) => {
      result.push({
        ...item,
        icon: icon,
      });
    };
    listMenu.forEach((parent) => {
      if (parent.children && parent.children.length > 0) {
        parent.children.forEach((item) => {
          pushResult(item, parent.icon);
        });
      } else {
        result.push(parent);
      }
    });

    return result;
  }

  async getDataKlinik() {
    this.isKaryawanLoading = true;
    this.clinicEnvService.clinicSet$.subscribe((clinic) => {
      if (clinic !== null) {
        this.clinic = clinic;
        let logoName = String(clinic.logo).split('/').pop();
        this.clinicLogo = `${this.clinicURL}${logoName}`;
        this.getPhoto(logoName);
      } else {
        this.dataKlinikService
          .getAllV2()
          .pipe(
            tap((res) => {
              this.clinic = res;
              let logoName = String(res.logo).split('/').pop();
              this.clinicLogo = `${this.clinicURL}${logoName}`;
              this.getPhoto(logoName);
            })
          )
          .subscribe();
      }
      this.sidebarService.initializeMenu();
    });

    this.sidebarService.menu$.pipe().subscribe((res) => {
      if (res.length > 0) {
        timer(500).subscribe(() => {
          this.isSidebarLoading = false;
        });
      } else {
        this.isSidebarLoading = false;
      }

      this.menuList = this.flattenMenuParents(res);
      this.sidebarMenu = res.map((item) => {
        let isOpen = this.router.url === '/antrien-pasien-poliklinik' && item?.title === 'Medis' ? true : false;
        return {
          sidebarMenu: item,
          isOpen: isOpen,
        };
      });
    });
  }

  searchMenu() {
    if (!this.searchText || this.searchText.trim().length < 1) {
      return (this.filteredMenuItems = null);
    }
    let filtered = this.menuList.filter((item) => {
      return item.title.toLowerCase().includes(this.searchText.toLowerCase().trim());
    });
    this.filteredMenuItems = filtered.length !== 0 ? filtered.slice(0, 5) : [];
    this.lengthListMenu = this.filteredMenuItems.length;
  }

  public toggleSubmenu(groupIndex: number) {
    if (this.sidebarMenu[groupIndex].isOpen === true) {
      this.sidebarMenu[groupIndex].isOpen = false;
    } else {
      this.sidebarMenu.forEach((item, index) => (item.isOpen = false));
      this.sidebarMenu[groupIndex].isOpen = true;
    }
    return;
  }

  public parseURL(url: string) {
    if (url.includes('?')) {
      const [target, paramsString] = url.split('?');
      let params: any = new URLSearchParams(paramsString);
      params = JSON.parse(
        '{"' + decodeURI(params.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
      );

      return { target, params };
    } else {
      return { target: url, params: {} };
    }
  }

  closeMenu() {
    if (this.menuController.isOpen()) {
      this.menuController.close();
      this.filteredMenuItems = null;
      this.searchText = '';
    }
  }

  /* ---------- Lifecycle methods ---------- */

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.storageService.setItem(storageDict.authorized, false);

    if (!this.platform.is('capacitor')) {
      // this.fcm.requestPermissionPWA();
      firebase.initializeApp(environment.firebase);
    }

    this.videoCallService.popOverState$
      .pipe(
        delay(100) // Temporary fix, refactor later.
      )
      .subscribe((res) => (this.videoCallPopover = res));

    this.storageService
      .getItem(storageDict.refreshToken)
      .pipe(
        tap((res) => {
          if (!res) {
            return this.authService.changeAuthState(false);
          }
          return this.authService.changeAuthState(true);
        })
      )
      .subscribe();

    defineCustomElements(window);
  }
}
