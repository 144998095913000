import { Navigasi } from './navigasi.model';
export class ReferenceNavigasi {
  constructor(
    public id: number,
    public nama: string,
    public url: string,
    public navigasi?: Navigasi,
    public tipeKlinik?: string,
    public icon?: string,
    public iconRefnavMobile?: string
  ) {}
}
