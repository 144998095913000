import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClinicEnvironmentService } from '../clinic-environment.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { Observable, throwError } from 'rxjs';
import { storageDict } from '../storage/storage-dict';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Karyawan } from 'app/models/karyawan.model';
@Injectable({
  providedIn: 'root',
})
export class VideoCallApiService {
  constructor(
    private clinicEnv: ClinicEnvironmentService,
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  public getAccessToken(roomName: string): Observable<{ token: string; roomName: string }> {
    return this.storageService.getItem(storageDict.karyawan).pipe(
      switchMap((karyawan: Karyawan) => {
        return this.httpClient.post(
          `${environment.urlMessaging}/facilities/${this.clinicEnv.clinicId}/telemedicine/video-token`,
          {
            roomName,
            identity: `karyawan-${karyawan.id}`,
          }
        );
      }),
      map((data: any) => {
        if (!data) return null;
        return { token: data.token, roomName: data.roomName };
      }),
      catchError((err) => throwError(err))
    );
  }
}
