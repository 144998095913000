import { Injectable } from '@angular/core';
import { Toast, ToastShowOptions } from '@capacitor/toast';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toast: HTMLIonToastElement;

  constructor(private _toastController: ToastController) {}

  async bottomToast(message: string, duration = 1000, options?: any): Promise<void> {
    this.toast = await this._toastController.create({
      message,
      duration,
      position: 'bottom',
      ...options,
    });
    await this.toast.present();
  }

  async showNativeToast(
    text: string,
    duration: 'short' | 'long',
    position: 'top' | 'center' | 'bottom'
  ): Promise<void> {
    const defaultOptions: ToastShowOptions = {
      text,
      duration,
      position,
    };
    return Toast.show(defaultOptions);
  }
}
