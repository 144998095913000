import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import ValueGuard from 'app/services/utils/value-guard';
import { isString } from 'lodash-es';
import { environment } from 'environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ApiEncryptionInterceptor implements HttpInterceptor {
  constructor() {}

  private decryptBody(cipher: string): Record<string, any> {
    try {
      const decodedBytes = atob(cipher);
      const byteLen = decodedBytes.length;

      const cipherBytes = new Uint8Array(byteLen);
      for (let i = 0; i < byteLen; i++) {
        cipherBytes[i] = decodedBytes.charCodeAt(i);
      }

      const iv = btoa(String.fromCharCode.apply(null, cipherBytes.slice(0, 16)));
      const encPayload = cipherBytes.slice(16);
      const encryptedData = btoa(encPayload.reduce((data, byte) => data + String.fromCharCode(byte), ''));

      const keyUtf8 = CryptoJS.enc.Utf8.parse(environment.encKey);
      const decrypted = CryptoJS.AES.decrypt(
        {
          ciphertext: CryptoJS.enc.Base64.parse(encryptedData),
          salt: '',
        } as any,
        keyUtf8,
        {
          iv: CryptoJS.enc.Base64.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
      return JSON.parse(plaintext);
    } catch (e) {
      throw new Error('Error While Decrypting: ' + e.message);
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((ev) => {
        if (ev instanceof HttpResponse) {
          if (ValueGuard.againtsNullOrUndefined(ev.body) && ev.body['_enc'] && isString(ev.body['data'])) {
            const decrypted = this.decryptBody(ev.body['data']);
            ev = ev.clone({ body: { ...decrypted, _processed: true } });
          }
        }
        return of(ev);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
