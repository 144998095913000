import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdrCurrencyPipe, IdrCurrencyPipeV2, IdrCurrencyPipeV3 } from './idr-currency.pipe';
import { HyphenPipe } from './hyphen.pipe.service';
import { CountdownPipe } from './countdown.pipe';
import { DateTimePipe } from './datetime.pipe';
import { SafePipe } from './safe.pipe';
import { RemoveTagPipe } from './remove-tag.pipe';
import { DatesPipe } from './dates.pipe';
import { MathPipe } from './math.pipe';
import { SearchfilterPipe } from './searchfilter.pipe';

@NgModule({
  declarations: [
    SearchfilterPipe,
    CountdownPipe,
    IdrCurrencyPipe,
    IdrCurrencyPipeV2,
    IdrCurrencyPipeV3,
    HyphenPipe,
    DateTimePipe,
    SafePipe,
    RemoveTagPipe,
    DatesPipe,
    MathPipe,
  ],
  exports: [
    SearchfilterPipe,
    CountdownPipe,
    IdrCurrencyPipe,
    IdrCurrencyPipeV2,
    IdrCurrencyPipeV3,
    HyphenPipe,
    DateTimePipe,
    SafePipe,
    RemoveTagPipe,
    DatesPipe,
    MathPipe,
  ],
  imports: [CommonModule],
  providers: [IdrCurrencyPipe, IdrCurrencyPipeV2, IdrCurrencyPipeV3, HyphenPipe, MathPipe],
})
export class PipesModule {}
