import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WebHookService } from './webhook.service';

@Injectable({
  providedIn: 'root',
})
export class ClientErrorHandler implements ErrorHandler {
  constructor(private webhook: WebHookService) {}

  handleError(err: Error) {
    console.error(err);
    if (err instanceof HttpErrorResponse) return of(null);
    return this.webhook
      .postClientError(err.name, err.message, window.location.href)
      .pipe(switchMap(() => throwError(err)));
  }
}
