import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ClinicEnvironmentService } from '../../clinic-environment.service';

@Injectable({
  providedIn: 'root',
})
export class SettingAuthSatuSehatApiService {
  constructor(private readonly _env: ClinicEnvironmentService, private readonly _http: HttpClient) {}

  public findAll(): Observable<{ dateMake: string }> {
    return this._http
      .post(`${environment.urlV2}/setting-auth-satusehat/all`, {
        idDataKlinik: this._env.clinicId,
      })
      .pipe(
        map((data: any) => {
          if (!data) return null;
          return data.responses.settingAuthSatuSehat[0];
        }),
        catchError((err) => throwError(err))
      );
  }
}
