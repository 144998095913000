import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StorageService } from '../services/storage/storage.service';
import { storageDict } from '../services/storage/storage-dict';
import { map, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpErrorService } from 'app/services/http-error-service';
import { AlertService } from 'app/services/alert-service';
import { ClinicEnvironmentService } from 'app/services/clinic-environment.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router
  ) {
    this.logOut = this.logOut.bind(this);
  }

  private async signOutUser() {
    await this.alertService.okAlertWithDismiss(
      'Sesi Anda sudah habis. Silakan Login Kembali',
      'Mohon Maaf',
      this.logOut
    );
  }

  async logOut() {
    await this.storage.clear();
    this.authService.changeAuthState(false);
    await this.router.navigateByUrl('/loginBackground');
  }

  setHeaders(req: HttpRequest<any>): (token: string) => HttpRequest<any> {
    return (obj: any) => {
      let token: string;
      if (obj !== null) {
        token = typeof obj === 'string' ? obj : obj.token;
      }

      const defaultHeaders = {
        accept: 'application/json',
        'app-source': 'mobile-klinik',
      };

      const setHeaders = token ? { ...defaultHeaders, Authorization: `Bearer ${token}` } : defaultHeaders;

      return req.clone({
        setHeaders,
      });
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /** Whitelisted route, will not need to have auth headers. */
    const whitelist = ['/sign-in', '/sign-up', '/sign-out', '/klinik/auth'];

    if (new RegExp(whitelist.join('|')).test(req.url.toLowerCase())) {
      return next.handle(req);
    }

    /** Handle null data-klinik, can be occured when it is not loaded. */
    // if (req.url.toLowerCase().includes('/data-klinik/null')) {
    //     console.warn(`WARN: Data-klinik is null. Service state: ${this.clinicEnv.clinicId}`);
    // }

    return this.storage.getItem(storageDict.token).pipe(
      map(this.setHeaders(req)),
      switchMap((req) => next.handle(req)),
      catchError((err) => {
        /**
         * ? If request is unauthorized (401) or forbidden, it is possible that our token is expired or not valid.
         * ? To resolve the issue, we ask a new token using available refresh token from the db.
         * ? It will try *once* again, and if the response is valid, it will save to the storage.
         * */
        if (err.status === 403 && this.authService.authState$.value) {
          return this.authService.getNewToken().pipe(
            tap((res) => this.storage.setItem(storageDict.token, res.token)),
            map(this.setHeaders(req)),
            switchMap((req) => next.handle(req)),
            catchError((err) => {
              if (err.status === 401 || err.status === 403) {
                this.signOutUser();
              }
              return throwError(err);
            })
          );
        }
        return throwError(err);
      })
    );
  }
}
