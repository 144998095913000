import { Injectable } from '@angular/core';
import io, { Socket } from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagingSocketService {
  private socket: Socket;
  private currentRoom = '';
  private connected$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  public onEvent(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (val: any) => {
        subscriber.next(val);
      });
    });
  }

  public emitEvent(eventName: string, data?: any): Observable<any> {
    return this.socket.emit(eventName, data);
  }

  public connectionStatus() {
    return this.connected$.asObservable();
  }

  public joinRoom(roomId: string) {
    this.socket.emit('joinRoom', roomId);
    if (roomId.includes('cssession_')) {
      return;
    }
    this.currentRoom = roomId;
  }

  public leaveRoom(roomId: string) {
    this.socket.emit('leaveRoom', roomId);
    this.currentRoom = '';
  }

  public initSocket() {
    const socketURL = new URL(`${environment.urlMessaging}`);

    this.socket = io(socketURL.origin, {
      upgrade: true,
      path: '/socket.io',
      transports: ['websocket'],
      autoConnect: true,
    });
    this.socket.on('connect', () => {
      this.connected$.next(true);
      if (this.currentRoom) {
        this.joinRoom(this.currentRoom);
      }
    });

    this.socket.on('connect_error', (err) => {
      this.connected$.next(false);
    });
    this.socket.on('disconnect', (data) => {
      this.connected$.next(false);
    });
  }
}
