export const storageDict = {
  activeTransaction: 'activeTransaction',
  idKaryawan: 'idKaryawan',
  karyawan: 'karyawan',
  klinik: 'klinik',
  idKategoriKaryawan: 'idKategoriKaryawan',
  idLogin: 'idLogin',
  idProfesi: 'idProfesi',
  refreshToken: 'refreshToken',
  token: 'token',
  transactions: 'transactions',
  headOffice: 'headOffice',
  fcmTopicsSubscribed: 'fcmTopicsSubscribed',
  fcmToken: 'fcm_token',
  lastSeenChat: 'lastSeenChat',
  blobs: 'blobs',
  dwConfig: 'dwConfig',
  customerCodeHistory: 'customerCodeHistory',
  dashboardAlertsClosed: 'dashboardAlertsClosed',
  appointmentUIToggle: 'appointmentUIToggle',
  PIN: 'PIN',
  isWarned: 'isWarned',
  fingerprint: 'fingerprint',
  faceID: 'faceID',
  authorized: 'authorized',
};
