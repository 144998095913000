import { Pipe, PipeTransform } from '@angular/core';
import { HyphenService } from '../services/hyphen.service';

@Pipe({
  name: 'hyphen',
})
export class HyphenPipe implements PipeTransform {
  constructor(private hyphenService: HyphenService) {}

  transform(num: number): string {
    if (!num) {
      return '';
    }

    const str = num.toString();

    return this.hyphenService.addHyphen(str);
  }
}
