import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Pusher from 'pusher-js';

@Injectable()
export class PusherProvider {
  private pusher: Pusher;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });
  }

  public init(channel: string) {
    return this.pusher.subscribe(channel);
  }
}
