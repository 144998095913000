import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingAuthSatuSehatApiService } from '../../api/settings/setting-auth-satusehat.api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingAuthSatuSehatDataService {
  constructor(private readonly _settingAuthSatuSehatApiService: SettingAuthSatuSehatApiService) {}

  public getAll(): Observable<{ dateMake: string }> {
    return this._settingAuthSatuSehatApiService.findAll();
  }
}
