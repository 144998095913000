import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'app/material/material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonUiModule } from '../_ui/common-ui.module';
import { PipesModule } from 'app/pipes/pipes.module';
import { IonicSelectableModule } from 'ionic-selectable';

import { PatientCardComponent } from './patient-card/patient-card.component';
import { ListFormComponent } from './list-form/list-form.component';
import { InputKeywordCommonComponent } from './input-keyword-common/input-keyword-common.component';
import { HistoryCardMedisComponent } from './history-card-medis/history-card-medis.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { BridgeMedisModalComponent } from './bridge-medis-modal/bridge-medis-modal.component';
import { MenuMedisDropdownComponent } from './menu-medis-dropdown/menu-medis-dropdown.component';
import { BridgeMedisNavigationModalComponent } from './bridge-medis-navigation-modal/bridge-medis-navigation-modal.component';

@NgModule({
  declarations: [
    PatientCardComponent,
    ListFormComponent,
    InputKeywordCommonComponent,
    HistoryCardMedisComponent,
    CommentModalComponent,
    BridgeMedisModalComponent,
    MenuMedisDropdownComponent,
    BridgeMedisNavigationModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MaterialModule,
    CKEditorModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUiModule,
    IonicSelectableModule,
  ],
  exports: [
    PatientCardComponent,
    ListFormComponent,
    InputKeywordCommonComponent,
    HistoryCardMedisComponent,
    CommentModalComponent,
    BridgeMedisModalComponent,
    MenuMedisDropdownComponent,
    BridgeMedisNavigationModalComponent,
  ],
})
export class MedisUiModule {}
