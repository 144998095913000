import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { DetailNavigasiAdapter } from '../adapters/detail-navigasi.adapter.service';
import { DetailNavigasi } from '../../models/detail-navigasi.model';

@Injectable({
  providedIn: 'root',
})
export class DetailNavigasiApiService {
  private url = `${environment.urlV2}/detail-navigasi`;

  constructor(private http: HttpClient, private adapter: DetailNavigasiAdapter) {}

  public getByLogin(loginId: number): Observable<DetailNavigasi[]> {
    return this.http.get(`${this.url}/login/${loginId}`).pipe(
      map((data: any) => {
        if (!data) return [];
        return data.responses.data.map((item: any) => this.adapter.adapt(item));
      }),
      catchError((err) => throwError(err))
    );
  }

  public createRecords(loginId: number, navItems: number[]): Observable<any> {
    return this.http
      .post(`${this.url}/login/${loginId}`, {
        navigationItems: navItems,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  public updateRecords(loginId: number, navigationItems: number[]): Observable<any> {
    return this.http
      .post(`${this.url}/login/${loginId}/update`, {
        navigationItems: navigationItems,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  public createNavigasiRegisterKlinik(loginId: number, data: any): Observable<any> {
    return this.http
      .post(`${environment.urlV2}/detail-navigasi/login/${loginId}`, data)
      .pipe(catchError((err) => throwError(err)));
  }
}
