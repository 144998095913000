import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DownloadPhotoApiService {
  constructor(private http: HttpClient) {}

  public getPhoto(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((data: any) => {
        if (!data) {
          return [];
        }
        return data;
      }),
      catchError((err) => of(null))
    );
  }
}
