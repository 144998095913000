import { ReferenceNavigasi } from './reference-navigasi.model';

/** This model is the header of the navigation, to get navigation details/children, refer to `reference-navigasi.model` */
export class Navigasi {
  constructor(public id: number, public nama?: string, public icon?: string, public urutan?: number) {}
}

/** Navigation Group to use in manage login - navigation / permissions managing */
export class NavigationGroup {
  constructor(
    public navParent: Navigasi,
    public listOpened: boolean,
    public children: NavigationChild[],
    public selectedLength?: number
  ) {}
}

export class NavigationChild {
  constructor(public navItem: ReferenceNavigasi, public selected: boolean) {}
}
