import { Pipe, PipeTransform } from '@angular/core';
import ValueGuard from 'app/services/utils/value-guard';

@Pipe({
  name: 'idrCurrency',
})
export class IdrCurrencyPipe implements PipeTransform {
  transform(val: string | number): string {
    if (!ValueGuard.againtsNullOrUndefined(val)) return '';
    if (val.toString().includes(',')) {
      val = val.toString().split(',')[0];
    }

    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    });

    if (val === undefined || val === null) {
      return '';
    }

    let num: number;
    if (typeof val === 'string') {
      num = +val;
    } else {
      num = val;
    }

    let currency = formatter.format(num);
    if (num % 1 === 0) {
      currency = currency.slice(0, -3);
    }

    return currency;
  }
}

@Pipe({
  name: 'idrCurrencyV2',
})
export class IdrCurrencyPipeV2 implements PipeTransform {
  transform(val: number | string, removeSymbol = false): string {
    if (!ValueGuard.againtsNullOrUndefined(val)) return '';
    if (val.toString().includes(',')) {
      val = val.toString().split(',')[0];
    }
    const options = removeSymbol
      ? {
          style: 'decimal',
        }
      : {
          style: 'currency',
          currency: 'IDR',
        };
    const formatter = Intl.NumberFormat('id-ID', options);
    const num: number = typeof val === 'string' ? +val : val;
    const currency = formatter.format(num);
    return currency;
  }
}

@Pipe({
  name: 'idrCurrencyV3',
})
export class IdrCurrencyPipeV3 implements PipeTransform {
  transform(val: number | string, removeSymbol = true): string {
    if (!ValueGuard.againtsNullOrUndefined(val)) return '';
    if (val.toString().includes(',')) {
      val = val.toString().split(',')[0];
    }
    const options = removeSymbol
      ? {
          style: 'decimal',
        }
      : {
          style: 'currency',
          currency: 'IDR',
        };
    const formatter = Intl.NumberFormat('id-ID', options);
    const num: number = typeof val === 'string' ? +val : val;
    const currency = formatter.format(num).replace(/\s/g, '');
    return currency;
  }
}
