import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { WebHookService } from 'app/services/webhook.service';
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  /** HTTP Error status that will be reported to the webhook. */
  private readonly ACCEPT_STATUS = [404, 500];

  constructor(private webhook: WebHookService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const whitelist = ['office.com'];

    if (new RegExp(whitelist.join('|')).test(req.url.toLowerCase())) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.error('err intercept', error, req);
        if (this.ACCEPT_STATUS.some((status) => status === error.status)) {
          return this.webhook
            .postHttpError(error.status.toString(), error.statusText, error.message, req.body, window.location.href)
            .pipe(switchMap(() => throwError(error)));
        } else {
          return throwError(error);
        }
      })
    );
  }
}
