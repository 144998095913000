import { Injectable } from '@angular/core';
import { DownloadPhotoDataService } from './data/download-photo.data.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class GetPhotoService {
  private photo$: Observable<any>;
  public unsubscribe$ = new Subject<void>();

  constructor(private downloadPhotoDataService: DownloadPhotoDataService, private sanitizer: DomSanitizer) {}

  getPhoto(url: string): Observable<any> {
    this.photo$ = this.downloadPhotoDataService.getPhoto(url).pipe(takeUntil(this.unsubscribe$));
    var subject = new Subject<any>();
    this.photo$.subscribe(async (res) => {
      const unsafeImageUrl = URL.createObjectURL(res);
      let imageToShow = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      subject.next(imageToShow);
    });
    return subject.asObservable();
  }
}
