import { Injectable } from '@angular/core';
import { Navigasi } from 'app/models/navigasi.model';
import { DetailNavigasi } from '../../models/detail-navigasi.model';
import { ReferenceNavigasi } from '../../models/reference-navigasi.model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class DetailNavigasiAdapter implements Adapter<DetailNavigasi> {
  public adapt(item: any): DetailNavigasi {
    return new DetailNavigasi(
      item.idDetailNav,
      new ReferenceNavigasi(
        item.idReferenceNavigation,
        item.nama,
        item.urlAngular,
        new Navigasi(item.idNavigation, item.namaHeader, item.iconMobile),
        item.typeKlinik,
        item.icon,
        item.iconRefnavMobile
      )
    );
  }
}
